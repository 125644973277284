export const thankYou = {
    intro: {
        h1: [
            `Looking`,
            `forward to`,
            `work`,
            `with you`,
        ],
        highlightLine: 2,
        actualLines: 4,
        h2: `Thank you for your submission, we'll contact you as soon as possible.`,
        button: {
            text: `Go home`,
            to: `/`,
        },
    },
};
