export const contacts = {
    intro: {
        h1: [
            `We can do`,
            `great things`,
            `together`,
            ``,
        ],
        highlightLine: 1,
        actualLines: 3,
        h2: `Let’s get acquainted. We are the Evristy team and you are...`,
        appearance: `secondary`,
        button: {
            text: ``,
            to: `/`,
        },
    },
    form: {
        success: `Thank you for your email, we'll contact you as soon as possible.`,
        fail: `Sorry, something went wrong, please try again.`,
        title: {
            divider: ` - `,
        },
        button: {
            text: `Submit`,
        },
        disclaimer: {
            text: `By clicking submit, you agree to our`,
            link: {
                text: `Privacy Policy`,
                href: `${process.env.PUBLIC_URL}/assets/files/privacy-policy.pdf`,
            },
        }
    }
};
