import { useEffect } from 'react';

import { notLightHouse } from './helpers';

const useScript = (url, async, defer, id) => {
    useEffect(() => {
        const script = document.createElement('script');
        if (notLightHouse()) {

            script.src = url;
            script.async = async;
            script.defer = defer;
            script.id = id;

            document.body.appendChild(script);
        }
        return () => {
            if (notLightHouse()) {
                document.body.removeChild(script);
            }
        }
    }, [url, async, defer, id]);
};

export default useScript;
