import research from '../media/services/research.jpg';
import design from '../media/services/design.jpg';
import consulting from '../media/services/consulting.jpg';
import growth from '../media/services/growth.jpg';

export const services = {
    intro: {
        h1: [
            `Not just`,
            `ideas, but`,
            `creative`,
            `solutions`,
        ],
        highlightLine: 2,
        actualLines: 4,
        h2: `We are not a fan of one-time 'freelance' jobs. Our talent and imagination are proven by our experience and are aimed to provide sustainable growth to our partners.`,
        button: {
            text: `Connect with us`,
            to: `/contact-us`,
        },
        appearance: `secondary`,
    },
    data: [
        {
            name: `Digital Product Discovery & Strategy`,
            category: `Research`,
            icon: `icon-dna`,
            description: `Developing a profound understanding of our customers by listening to their needs, pain points, desires, goals and motivations, we create vital products for them. It's important for us to build empathy for the people who will use or be impacted by our solutions. To avoid product failure, we minutely conduct data and research-based product discovery.`,
            offers: [
                `Product Strategy`,
                `Project Management`,
                `Roadmapping`,
                `Post-launch optimization`,
            ],
            theme: {
                color: `white`,
                background: `#161a25`,
            },
            image: research,
            button: {
                text: `I need a solution`,
            },
        },
        {
            name: `Product Design & Branding`,
            category: `Design`,
            icon: `icon-pen-nib`,
            description: `Our primary strategy is to create a product design based on both the clients’ needs and business goals. We build effortlessly seamless experiences, utilizing prototyping and usability testing for idea validation. Highly prioritizing problem-solving through design, we carefully study users' interaction with the product to deliver the best results.`,
            offers: [
                `UI`,
                `UX`,
                `Wireframing`,
                `Branding`,
                `Graphic Design`,
            ],
            theme: {
                color: `black`,
                background: `#ffc32f`,
            },
            image: design,
            button: {
                text: `I need a design`,
            },
        },
        {
            name: `Business Intelligence & Consulting`,
            category: `Consulting`,
            icon: `icon-kaaba`,
            description: `We collect, organize, and present data that help company executives and managers make informed business decisions. Connecting business management principles and available technologies we also significantly improve business efficiency, minimize operating costs, and increase profits.`,
            offers: [
                `Data Analytics`,
                `Decision Making`,
                `Process optimization`,
            ],
            theme: {
                color: `white`,
                background: `#06152f`,
            },
            image: consulting,
            button: {
                text: `Consult me`,
            },
        },
        {
            name: `Marketing`,
            category: `Growth`,
            icon: `icon-fingerprint`,
            description: `With a great focus on ROI, we are ready to manage your business reputation and increase sales volume. Craft your robust marketing plan. Deliver organic results for your business through careful keyword research, results-driven SEO, white hat practices, trackable web analytics, targeted digital campaigns and lead-driven content.`,
            offers: [
                `Brand Strategy`,
                `SEO`,
                `Content Strategy`,
                `Post-launch optimization`,
            ],
            theme: {
                color: `white`,
                background: `#211c25`,
            },
            image: growth,
            button: {
                text: `I want to grow`,
            },
        },
    ],
};
