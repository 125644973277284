import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Button from '../button';
import Animate from '../animate';

import { content } from '../../content/content';

import video from './media/smokeBlur.mp4';

import './intro.scss';

const enableVideo = true;

const Intro = props => {
    const {
        slug,
    } = props;

    const [currentContent, setCurrentContent] = useState({});

    useEffect(() => {
        setCurrentContent(content[slug] ? content[slug].intro : {});
    }, [slug]);

    const render = !!Object.keys(currentContent).length;

    return (
        <section
            className={classnames(
                `intro-holder`,
                `a-${currentContent.appearance}`,
            )}
        >
            <div className='guide'>
                <ul className='intro-content'>
                    <li>
                        {(render && currentContent.h1) && (
                            <Animate delay={200}>
                                <h1
                                    style={{
                                        height: `calc(var(--line-height) * ${currentContent.actualLines})`,
                                    }}
                                >
                                    {currentContent.h1?.map((line, index) => (
                                        <span
                                            key={index}
                                            className={classnames(
                                                {
                                                    'highlight': index === currentContent.highlightLine,
                                                    'medium': index === currentContent.highlightLine && line.split(' ').length === 2,
                                                }
                                            )}
                                        >
                                            {`${line} `}
                                        </span>
                                    ))}
                                </h1>
                            </Animate>
                        )}
                    </li>
                    <li>
                        {render && (
                            <Animate delay={400}>
                                <h2>
                                    {currentContent.h2}
                                </h2>
                                <div
                                    className={classnames(
                                        'intro-button-holder',
                                        {
                                            'show': currentContent?.button?.text,
                                        }
                                    )}
                                >
                                    {currentContent.button && (
                                        <Button
                                            size='extra-big'
                                            appearance='clean'
                                            flexibility='content-size'
                                            icon='icon-arrow-right-line-thin'
                                            itemsDirection='end'
                                            iconSize={26}
                                            to={currentContent.button?.to}
                                            tag='NavLink'
                                        >
                                            {currentContent.button?.text}
                                        </Button>
                                    )}
                                </div>
                            </Animate>
                        )}
                    </li>
                </ul>
            </div>
            {enableVideo && (
                <div className='video-holder'>
                    <Animate animation='fadeIn'>
                        <div className='video-animate'>
                            <video
                                autoPlay
                                muted
                                loop
                                playsInline
                            >
                                <source
                                    src={video}
                                    type='video/mp4'
                                />
                            </video>
                        </div>
                    </Animate>
                </div>
            )}
        </section>
    )
};

Intro.propTypes = {
    slug: PropTypes.string,
};

export default Intro;
