import React from 'react';

import { home } from './home';

import team from '../media/about/team.png';

import emil from '../media/about/emil.jpg';
import hovo from '../media/about/hovo.jpg';
import fred from '../media/about/fred.jpg';
import jemma from '../media/about/jemma.jpg';
import arsen from '../media/about/arsen.jpg';
import jacob from '../media/about/jacob.jpg';
import hrayr from '../media/about/hrayr.jpg';
import adelina from '../media/about/adelina.jpg';
import anahit from '../media/about/anahit.jpg';
import mher from '../media/about/mher.jpeg';

export const about = {
    intro: {
        h1: [
            `We`,
            `love`,
            `our job`,
            ``,
        ],
        highlightLine: 1,
        actualLines: 3,
        h2: `We are a team of Technologists, Generalists and Business-oriented people passionate about Technology, Design, and Growth.`,
        button: {
            text: `Connect with us`,
            to: `/contact-us`,
        },
        appearance: `secondary`,
    },
    image: team,
    cardsGroup: {
        title: (
            <>
                Our <span>Vision</span> & <span>Approach</span>
            </>
        ),
        text: `Our vision is to help create a world of digital products that not only bring value to its consumers but also sustainable growth to its founders.`,
        data: home.cardsGroup.data,
    },
    members: {
        title: (
            <>
                We are a <span>team</span> of
            </>
        ),
        text: `Enthusiasts who are passionate about our work`,
        data: [
            {
                firstName: `Emil`,
                lastName: `Hakobian`,
                photo: emil,
                theme: `black`,
                social: [
                    {
                        icon: `icon-linkedin`,
                        url: `https://www.linkedin.com/in/emilhakobian/`,
                    },
                ],
                position: `Principal - Co-Founder`,
            },
            {
                firstName: `Hovhannes`,
                lastName: `Gyanjyan`,
                photo: hovo,
                theme: `white`,
                social: [
                    {
                        icon: `icon-linkedin`,
                        url: `https://www.linkedin.com/in/hovhannes-gyanjyan-a1740066/`,
                    },
                ],
                position: `Product Lead - Co-Founder`,
            },
            {
                firstName: `Jacob`,
                lastName: `Clyne`,
                photo: jacob,
                theme: `white`,
                social: [
                    {
                        icon: `icon-linkedin`,
                        url: `https://www.linkedin.com/in/jacobclyne/`,
                    },
                ],
                position: `Creative Director - Co-Founder`,
            },
            {
                firstName: `Fred`,
                lastName: `Tatlyan`,
                photo: fred,
                theme: `black`,
                social: [
                    {
                        icon: `icon-linkedin`,
                        url: `https://www.linkedin.com/in/fred-tatlyan-038a4b6/`,
                    },
                ],
                position: `Advisor`,
            },
            {
                firstName: `Jemma`,
                lastName: `Karapetyan`,
                photo: jemma,
                theme: `black`,
                social: [
                    {
                        icon: `icon-linkedin`,
                        url: `https://www.linkedin.com/in/jemma-karapetyan/`,
                    },
                ],
                position: `Product Manager`,
            },
            {
                firstName: `Arsen`,
                lastName: `Duztapanyan`,
                photo: arsen,
                theme: `black`,
                social: [
                    {
                        icon: `icon-linkedin`,
                        url: `https://www.linkedin.com/in/arsen-duztapanyan-7aa9112a/`,
                    },
                ],
                position: `Product Manager`,
            },
            {
                firstName: `Adelina`,
                lastName: `Zargaryan`,
                photo: adelina,
                theme: `white`,
                social: [
                    {
                        icon: `icon-linkedin`,
                        url: `https://www.linkedin.com/in/adelina-zargaryan/`,
                    },
                ],
                position: `Product Manager`,
            },
            {
                firstName: `Anahit`,
                lastName: `Tsaturyan`,
                photo: anahit,
                theme: `black`,
                social: [
                    {
                        icon: `icon-linkedin`,
                        url: `https://www.linkedin.com/in/anahit-tsaturyan/`,
                    },
                ],
                position: `Product Manager`,
            },
            {
                firstName: `Mike`,
                lastName: `Gabby`,
                photo: mher,
                theme: `white`,
                social: [],
                position: `Graphic Designer`,
            },
            {
                firstName: `Hrair`,
                lastName: `Sharoyan`,
                photo: hrayr,
                theme: `black`,
                social: [
                    {
                        icon: `icon-linkedin`,
                        url: `https://www.linkedin.com/in/hrayr-sharoyan-724751174/  `,
                    },
                ],
                position: `UI/UX Designer`,
            },
        ],
    },
};
