import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { blogEnabled } from '../../constants/config';

import Icon from '../icon';

import { routes } from '../../constants/routes';
import { content } from '../../content/content';

import { navLinkClick } from '../../utils/helpers';

import './footer.scss';

const Footer = props => {
    const {
        blogAvailable,
    } = props;

    return (
        <footer>
            <div className='guide'>
                <ul className='footer-content'>
                    <li>
                        <div className='contact-us-footer'>
                            <p>
                                {content.footer.contacts.primary[0]}
                            </p>
                            <p>
                                <NavLink
                                    exact
                                    to='/contact-us'
                                    className='link-effect'
                                    onClick={() => navLinkClick('/contact-us')}
                                >
                                    {content.footer.contacts.primary[1]}
                                </NavLink>
                                {content.footer.contacts.primary[2]}
                            </p>
                        </div>
                        <ul className='contact-mail'>
                            <li>
                                {content.footer.contacts.secondary}
                            </li>
                            <li>
                                <a
                                    href={`mailto:${content.footer.contacts.email}`}
                                    className='link-effect'
                                >
                                    {content.footer.contacts.email}
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <div className='footer-widget'>
                            <ul>
                                {routes.map((route, index) => (!route.invisible && !route.hidden) && (route.slug === 'blog' ? (blogAvailable && blogEnabled) : true) && (
                                    <li key={index}>
                                        {route.target ? (
                                            <a
                                                href={route.url}
                                                target={route.target}
                                            >
                                                {route.name}
                                            </a>
                                        ) : (
                                            <NavLink
                                                to={route.url}
                                                onClick={() => navLinkClick(route.url)}
                                                exact={route.url === '/'}
                                            >
                                                {route.name}
                                            </NavLink>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </li>
                    <li>
                        <div className='footer-widget'>
                            {content.footer.address.map((line, index) => (
                                <p key={index}>
                                    {line}
                                </p>
                            ))}
                        </div>
                    </li>
                    <li className='footer-social-holder'>
                        <ul className='footer-social-links'>
                            {content.footer.socialLinks.map((link, index) => (
                                <li key={index}>
                                    <a
                                        rel='noopener noreferrer'
                                        href={link.url}
                                        target='_blank'
                                        aria-label={`Find us on: ${link.label}`}
                                    >
                                        <Icon type={link.icon}/>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </li>
                </ul>
                <div className='copyright-holder'>
                    {content.footer.copyright}
                </div>
            </div>
        </footer>
    )
};

Footer.propTypes = {
    blogAvailable: PropTypes.bool,
};

export default Footer;
