export const portfolio = {
    intro: {
        h1: [
            `Check`,
            `Out`,
            `Our Works`,
        ],
        h2: `Showcasing all the digital products, applications and websites designed by Evristy's team!`,
        highlightLine: 2,
        actualLines: 3,
        appearance: `secondary`,
        button: {
            text: `Contact us`,
            to: `/contact-us`,
        },
    },
};
