import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './icon.scss';

const Icon = props => {
    const {
        type,
        className,
        size,
        onClick,
        ...restProps
    } = props;

    return (
        <i
            {...restProps}
            className={
                classnames(
                    'icon',
                    `s-${size}`,
                    type,
                    className,
                    {
                        'pointer-events-none': !onClick,
                    })}
            onClick={onClick}
        />
    )
};

Icon.propTypes = {
    type: PropTypes.string,
    size: PropTypes.oneOf([120, 80, 48, 32, 26, 24, 20, 18]),
    className: PropTypes.string,
};

Icon.defaultProps = {
    size: 24,
};

export default Icon;
