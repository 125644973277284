import React, { Suspense, useEffect, useState } from 'react';
import axios from 'axios';
import { hotjar } from 'react-hotjar';
import ReactPixel from 'react-facebook-pixel';
import classnames from 'classnames';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import Intro from '../../components/intro';
import Header from '../../components/header';
import Aside from '../../components/aside';
import Footer from '../../components/footer';
import Loader from '../../components/loader';

import { blogEnabled, webSiteName, webSiteTitleDivider } from '../../constants/config';

import { routes } from '../../constants/routes';
import { useScrollDirection } from '../../utils/useScrollDirection';

import useScript from '../../utils/useScript';
import { notLightHouse } from '../../utils/helpers';

const Layout = () => {
    const scrollDirection = useScrollDirection();
    const location = useLocation();
    const [slug, setSlug] = useState('home');
    const [invisible, setInvisible] = useState(false);
    const [blogData, setBlogData] = useState([]);
    const [blogAvailable, setBlogAvailable] = useState(true);

    useScript('https://js.hs-scripts.com/7415236.js', true, true, 'hs-script-loader');

    useEffect(() => {
        if (notLightHouse()) {
            hotjar.initialize(1766280, 6);
            ReactPixel.init('309761253329082');
            ReactPixel.pageView();
        }
    }, []);

    useEffect(() => {
        const pathname = window.location.pathname;
        const route = routes.find(route => route.url === (pathname.split('/').length > 2 ? '/portfolio/:work' : pathname));
        const metaTags = document.querySelectorAll('.dynamic-helmet');
        const title = pathname === '/' ? route?.meta.title : `${route?.meta.title} ${webSiteTitleDivider} ${webSiteName}`;

        document.documentElement.scrollTo({
            top: 0,
        });

        document.title = title;
        metaTags.forEach(tag => {
            if(tag.hasAttribute('class')) {
                const dataType = tag.getAttribute('class').split(' ')[1];
                switch (dataType) {
                    case 'url':
                        tag.setAttribute('content', window.location.href);
                        break;
                    case 'url-link':
                        tag.setAttribute('href', window.location.href);
                        break;
                    case 'title':
                        tag.setAttribute('content', title);
                        break;
                    default:
                        tag.setAttribute('content', route.meta[dataType]);
                }
            }
        });

        if(process.env.NODE_ENV === 'development') {
            document.documentElement.classList.add(process.env.NODE_ENV)
        }

        setInvisible(!!route.invisible);
        setSlug(route.slug);
    }, [location.pathname, location.hash]);

    useEffect(() => {
        if(notLightHouse() && blogEnabled) {
            axios.get('https://www.evristy.com/blog/rss.xml')
                .then((response) => {
                    setBlogAvailable(response.status !== 403);
                    if(response.status === 200) {
                        const parser = new DOMParser();
                        const xmlDoc = parser.parseFromString(response.data,"text/xml");
                        const parsedItems = xmlDoc.getElementsByTagName('item');
                        Object.values(parsedItems).forEach((item, index) => {
                            index < 4 && setBlogData(prev => [
                                ...prev,
                                {
                                    title: item.getElementsByTagName('title')[0]?.textContent,
                                    category: item.getElementsByTagName('category')[0]?.textContent,
                                    categoryLink: item.getElementsByTagName('categoryLink')[0]?.textContent,
                                    image: item.getElementsByTagName('image')[0]?.getElementsByTagName('url')[0].textContent,
                                    link: item.getElementsByTagName('link')[0]?.textContent,
                                }
                            ]);
                        });
                    }
                }).catch(() => {});
        }
    }, []);

    const sharedProps = {
        blogData,
        blogAvailable,
    };

    return (
        <>
            <div
                className={classnames(
                    `main-holder`,
                    `s-${slug}`,
                    `sd-${scrollDirection}`,
                )}
            >
                <Aside/>
                {!invisible && (
                    <Header {...sharedProps}/>
                )}
                <main>
                    <Intro
                        slug={slug}
                    />
                    <Switch>
                        {routes.map((route, key) => !!route.component && (
                            <Route
                                exact
                                path={route.url}
                                key={key}
                            >
                                <Suspense fallback={<Loader/>}>
                                    {route.component(sharedProps)}
                                </Suspense>
                            </Route>
                        ))}
                        <Redirect to='/404'/>
                    </Switch>
                </main>
            </div>
            {!invisible && (
                <Footer {...sharedProps}/>
            )}
        </>
    )
};

export default Layout;
