import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { InView } from 'react-intersection-observer'

import './animate.scss';

const Animate = props => {
    const {
        delay,
        animation,
        children,
    } = props;

    return (
        <InView triggerOnce>
            {({ inView, ref }) => {
                return (
                    <div
                        ref={ref}
                        className={classnames(
                            `animated-holder`,
                            `a-${animation}`,
                            {
                                'animate': inView,
                            }
                        )}
                        style={{
                            animationDelay: `${delay}ms`,
                        }}
                    >
                        {children}
                    </div>
                )
            }}
        </InView>
    )
};

Animate.defaultProps = {
    delay: 0,
    animation: 'fadeInUp',
};

Animate.propTypes = {
    delay: PropTypes.number,
    children: PropTypes.node,
    animation: PropTypes.oneOf(['fadeInUp', 'fadeIn']),
};

export default Animate;
