import React from 'react';

import { currentYear } from '../../utils/helpers';

import { ReactComponent as Advelit } from '../media/ourClients/advelit.svg';
import { ReactComponent as Nvak } from '../media/ourClients/nvak.svg';
import { ReactComponent as Arshavner } from '../media/ourClients/arshavner.svg';
import { ReactComponent as BlueHorizon } from '../media/ourClients/blueHorizon.svg';
import { ReactComponent as Cc } from '../media/ourClients/cc.svg';
import { ReactComponent as Master } from '../media/ourClients/master.svg';

export const components = {
    whatWeDo: {
        title: (
            <>
                What <span>we</span> do
            </>
        ),
        text: `Here at Evristy, our mission is to help businesses solve their pain points and make their digital transformation easy and sustainable by providing excellent user experience and design. While keeping your customers at the center, we aim to bring maximum value to your business.`,
        button: {
            text: `Discover services`,
        },
    },
    ourClients: {
        title: (
            <>
                They <span>trust</span> us
            </>
        ),
        data: [
            <Advelit/>,
            <Nvak/>,
            <Arshavner/>,
            <BlueHorizon/>,
            <Master/>,
            <Cc/>,
        ],
    },
    footer: {
        address: [
            `26a Movses Khorenatsi St.`,
            `Yerevan, 0010`,
            `Armenia`,
        ],
        contacts: {
            email: process.env.REACT_APP_CONTACT_EMAIL,
            primary: [
                `Don't be shy,`,
                `say "hi"`,
                `.`,
            ],
            secondary: `Or email us:`,
        },
        socialLinks: [
            {
                icon: `icon-facebook`,
                url: process.env.REACT_APP_FACEBOOK,
                label: `Facebook`,
                value: `Facebook`,
            },
            {
                icon: `icon-linkedin`,
                url: process.env.REACT_APP_LINKEDIN,
                label: `LinkedIn`,
                value: `LinkedIn`,
            },
            {
                icon: `icon-twitter`,
                url: process.env.REACT_APP_TWITTER,
                label: `Twitter`,
                value: `Twitter`,
            },
        ],
        copyright: `${currentYear} © ${process.env.REACT_APP_WEBSITE_NAME}. All rights reserved.`,
    },
};
