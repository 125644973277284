import React, { lazy } from 'react';

import { webSiteTitle, webSiteMetaDescription, webSiteMetaKeywords, blogEnabled, portfolioEnabled } from './config';

const Contacts = lazy(() => import('../containers/contacts').then());
const About = lazy(() => import('../containers/about').then());
const Services = lazy(() => import('../containers/services').then());
const Home = lazy(() => import('../containers/home').then());
const Portfolio = lazy(() => import('../containers/portfolio').then());
const PortfolioSubPage = lazy(() => import('../containers/portfolioSubPage').then());

export const routes = [
    {
        name: 'Home',
        url: '/',
        component: props => <Home {...props} />,
        slug: 'home',
        meta: {
            title: webSiteTitle,
            description: webSiteMetaDescription,
            keywords: webSiteMetaKeywords,
        },
    },
    {
        name: 'Services',
        url: '/services',
        component: () => <Services />,
        slug: 'services',
        meta: {
            title: 'Digital Product Design | Product Management & Strategy',
            description: 'Evristy helps businesses to build products and services that provide a positive customer experience in order to drive profit and gain competitive advantage.',
            keywords: webSiteMetaKeywords,
        },
    },
    ...(portfolioEnabled ? ([
        {
            name: 'Portfolio',
            url: '/portfolio',
            component: () => <Portfolio />,
            slug: 'portfolio',
            meta: {
                title: 'Portfolio',
                description: `On this page of our website we are showcasing all the digital products, applications and websites designed by Evristy's team! Design new products and websites or solve usability issues for existing ones`,
                keywords: webSiteMetaKeywords,
            },
        },
        {
            name: 'Portfolio',
            url: '/portfolio/:work',
            hidden: true,
            component: () => <PortfolioSubPage />,
            slug: 'portfolioSubPage',
            meta: {
                title: 'Portfolio',
                description: `On this page of our website we are showcasing all the digital products, applications and websites designed by Evristy's team! Design new products and websites or solve usability issues for existing ones`,
                keywords: webSiteMetaKeywords,
            },
        },
    ]) : []),
    {
        name: 'About',
        url: '/about',
        component: () => <About />,
        slug: 'about',
        meta: {
            title: 'About us',
            description: 'We are a team of professionals aimed to help you reach business goals through design thinking and human-centered approaches. Start today!',
            keywords: webSiteMetaKeywords,
        },
    },
    ...(blogEnabled ? [{
        name: 'Blog',
        url: `${process.env.REACT_APP_WEBSITE_URL}/blog`,
        target: '_self',
        slug: 'blog',
    }] : []),
    {
        name: 'Contact us',
        url: '/contact-us',
        component: () => <Contacts />,
        slug: 'contacts',
        meta: {
            title: 'Contact us',
            description: 'Start the digital transformation of your business and reach your business goals and objectives. Get free consultancy today on how to build your digital transformation strategy!',
            keywords: webSiteMetaKeywords,
        },
    },
    {
        name: 'Page not found',
        url: '/404',
        component: () => null,
        invisible: true,
        slug: '404',
        meta: {
            title: 'Page not found',
            description: webSiteMetaDescription,
            keywords: webSiteMetaKeywords,
        },
    },
    {
        name: 'Thank You',
        url: '/thank-you',
        component: () => null,
        invisible: true,
        slug: 'thankYou',
        meta: {
            title: 'Thank You',
            description: webSiteMetaDescription,
            keywords: webSiteMetaKeywords,
        },
    },
];
