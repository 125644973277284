import React from 'react';

import Icon from '../icon';
import Animate from '../animate';

import { content } from '../../content/content';

import './aside.scss';

const Aside = () => {
    return (
        <aside>
            <ul className='social-links'>
                {content.footer.socialLinks.map((link, index) => (
                    <li key={index}>
                        <Animate delay={800}>
                            <a
                                rel='noopener noreferrer'
                                href={link.url}
                                target='_blank'
                                aria-label={`Find us on: ${link.label}`}
                            >
                                <Icon type={link.icon} size={20}/>
                            </a>
                        </Animate>
                    </li>
                ))}
            </ul>
        </aside>
    )
};

export default Aside;
