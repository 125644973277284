export const toggleClass = (state, className, element = document.documentElement) => {
    state ? element.classList.add(className) : element.classList.remove(className);
};

export const getCookie = cname => {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
};

export const navLinkClick = to => {
    if(window.location.pathname === to) {
        document.documentElement.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }
};

export const currentYear = new Date().getFullYear();

export const capitalize = s => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export const notLightHouse = () => {
    return !!(navigator.userAgent.indexOf('Lighthouse') === -1 && process.env.NODE_ENV !== 'development');
}
