import { home } from './pieces/home';
import { services } from './pieces/services';
import { about } from './pieces/about';
import { contacts } from './pieces/contacts';
import { pageNotFound } from './pieces/pageNotFound';
import { components } from './pieces/components';
import { thankYou } from './pieces/thankYou';
import { portfolio } from './pieces/portfolio';
import { portfolioSubPage } from './pieces/portfolioSubPage';

export const content = {
    home: {
        ...home,
    },
    services: {
        ...services,
    },
    about: {
        ...about,
    },
    contacts: {
        ...contacts,
    },
    404: {
        ...pageNotFound,
    },
    thankYou: {
        ...thankYou,
    },
    portfolio: {
        ...portfolio,
    },
    portfolioSubPage: {
        ...portfolioSubPage,
    },
    ...components,
};
