import React from 'react';
import PropTypes from 'prop-types';

const Logo = props => {
    const {
        type,
    } = props;

    return type === 'letter' ? (
        <svg viewBox='0 0 29.523 50.6' className='logo-letter'>
            <path
                d='M25.5195,21.3154h.019a3.9845,3.9845,0,0,1,3.9845,3.9845h0a3.9845,3.9845,0,0,1-3.9845,3.9845h-.019a3.9845,3.9845,0,0,1-3.9845-3.9845h0A3.9845,3.9845,0,0,1,25.5195,21.3154Z'
                className='fill-hero'
            />
            <path d='M29.5,50.6H0V0H29.5V2.6H2.8V48H29.5Z'/>
        </svg>
    ) :(
        <svg viewBox='0 0 420 52' className='logo-full'>
            <rect className='fill-hero' x='21.535' y='22.0154' width='7.988' height='7.969' rx='3.9845' />
            <path d='M29.5,51.3H0V0.7h29.5v2.6H2.8v45.4h26.7V51.3z'/>
            <path d='M81.8,51.3L62.5,0.7h3l17.7,46.9h0.2L101,0.7h2.9L84.6,51.3H81.8z'/>
            <path d='M207,51.3V0.7h2.8v50.5H207z'/>
            <path d='M329.4,51.3v-48h-17.8V0.7h38.3v2.6h-17.7v48H329.4z'/>
            <path d='M398.8,51.3v-22L380.4,0.7h3.3l16.5,25.7h0.2l16.5-25.7h3.2l-18.4,28.5v22H398.8z'/>
            <path d='M159.2,29.2c7.4-1.5,11.7-6.7,11.7-14c0-8.9-6.2-14.4-16.3-14.4h-16.4v50.5h2.8V29.7v-2.5V3.3h13.4c8.5,0,13.7,4.4,13.7,12c0,7.3-4.9,11.8-13.2,11.9l1.4,2.4L169,51.3h3.2L159.2,29.2z'/>
            <path d='M278.3,37.9c-0.5,6.9-6.8,11.6-15.7,11.6c-8.6,0-14.6-4.6-14.6-11.3c0-5.5,3.7-8.5,12.5-10.5l5.4-1.3c9.9-2.3,13.9-6.2,13.9-12.9c0-7.9-7.4-13.4-16.6-13.4c-9.5,0-16.7,5.6-17,13.1h2.8c0.4-6.1,6.3-10.5,14.3-10.5c7.5,0,13.7,4.2,13.7,10.8c0,5.4-3.7,8.5-12,10.4l-5.4,1.2c-10.3,2.4-14.4,6-14.4,12.9c0,8.4,7.1,14.1,17.5,14.1c10.5,0,18-5.8,18.4-14.1H278.3z'/>
        </svg>
    )
};

Logo.propTypes = {
    type: PropTypes.oneOf(['full', 'letter',]),
};

export default Logo;
