import React from 'react';

import './loader.scss';

const Loader = () => {
    return (
        <div className='loader-holder' />
    )
};

export default Loader;
