import React from 'react';

import { blogEnabled } from '../../constants/config';

import airplanes from '../media/home/airplanes.svg';
import bulb from '../media/home/bulb.svg';

export const home = {
    intro: {
        h1: [
            `We`,
            `design`,
            `digital`,
            `products`,
        ],
        highlightLine: 1,
        actualLines: 4,
        h2: `Evristy is different because we not only design and concept with you but also build the product. Our goal is to provide top-notch problem-solving ideas that are measurable and objective oriented.`,
        button: {
            text: `Hire us`,
            to: `/contact-us`,
        },
    },
    cardsGroup: {
        title: (
            <>
                Our <span>Approach</span>
            </>
        ),
        text: `Building the right thing instead of "building the thing right".`,
        data: [
            {
                texts: [
                    `We respect Design thinking as a practical method for solving the problems That’s why defining and clarifying the problem thoroughly is key for us. Building a prototype allows us to test hypotheses and validate the direction in which we decided to move. We establish new solutions and features through feedback and measurable objectives.`,
                    `We are data-driven which helps us in making decisions and assumptions.`,
                ],
                image: bulb,
            },
            {
                texts: [
                    `We are Agile, and we are ready to adapt our workflow with our customers' processes. Regular touchpoints with customers help us research and achieve certain product outcomes. Iterative customer feedback ensures that you do not invest time designing features or solutions that don’t work.`,
                    `We determine success or failure by setting goals and measuring objectives.`,
                ],
                image: airplanes,
            },
        ],
    },
    ...(blogEnabled ? ({
        blog: {
            title: (
                <>
                    Latest from our <span>Blog</span>
                </>
            ),
            text: `Design, UI, UX, Digital Transformation, Product Management & more.`,
        },
    }) : {}),
};
