export const pageNotFound = {
    intro: {
        h1: [
            `Sorry`,
            `page`,
            `not`,
            `found`,
        ],
        highlightLine: 0,
        actualLines: 4,
        h2: `This is so embarrassing.`,
        button: {
            text: `Go home`,
            to: `/`,
        },
    },
};
