import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { NavLink } from "react-router-dom";

import Icon from '../icon';

import { navLinkClick } from '../../utils/helpers';

import './button.scss';

const buttonConfig = {
    appearance: [
        'default',
        'outline',
        'minimal',
        'grayscale',
        'light',
        'clean',
    ],
    size: [
        'small',
        'medium',
        'default',
        'big',
        'extra-big',
        'in-big',
    ],
    color: [
        'hero',
        'accent',
        'brand',
        'danger',
        'confirm',
        'warning',
        'default',
        'peach',
        'jaffa',
    ],
    flexibility: [
        'full-width',
        'content-size',
        'default',
    ],
    itemsDirection: [
        'start',
        'end',
    ],
    cornerRadius: [
        'round',
        'smooth',
    ],
    tag: [
        'button',
        'a',
        'NavLink',
    ],
    type: [
        'button',
        'submit',
        'reset',
    ],
    tooltipPosition: [
        'bottom',
        'top',
        'left',
        'right',
    ],
};

const Button = React.forwardRef((props, ref) => {
    const {
        active,
        animation,
        appearance,
        badge,
        children,
        className,
        color,
        cornerRadius,
        flexibility,
        icon,
        iconSize,
        itemsDirection,
        size,
        tag,
        tooltip,
        tooltipPosition,
        type,
        loading,
        disabled,
        to,
        ...restProps
    } = props;

    const sharedProps = {
        'data-tip': tooltip,
        'data-tip-place': tooltipPosition,
        className: classnames(
            'btn',
            className,
            `s-${size}`,
            `c-${color}`,
            `a-${appearance}`,
            `f-${flexibility}`,
            `cr-${cornerRadius}`,
            `id-${itemsDirection}`,
            {
                animation,
                loading,
                'active': active,
                'c-icon': !!icon && !children,
                'c-one': children && children.toString().length === 1,
            }
        ),
        type,
        ref,
        ...restProps
    };

    const content = () => (
        <>
            {(icon || loading) && <Icon type={loading ? 'icon-spinner-third' : icon} size={iconSize} />}
            {!!children && (
                <span className="ellipsis-text">{children}</span>
            )}
            {!!badge && <span className="badge-count">{badge}</span>}
        </>
    );

    switch (tag) {
        case 'a':
            return (
                <a
                    rel="noopener noreferrer"
                    {...sharedProps}
                >
                    {content()}
                </a>
            );
        case 'NavLink':
            return (
                <NavLink
                    {...sharedProps}
                    onClick={() => navLinkClick(to)}
                    to={to}
                >
                    {content()}
                </NavLink>
            );
        default:
            return (
                <button {...sharedProps} disabled={disabled}>
                    {content()}
                </button>
            )
    }
});

Button.defaultProps = {
    active: false,
    animation: false,
    appearance: buttonConfig.appearance[0],
    color: buttonConfig.color[0],
    cornerRadius: buttonConfig.cornerRadius[0],
    flexibility: buttonConfig.flexibility[0],
    iconSize: Icon.defaultProps.size,
    itemsDirection: buttonConfig.itemsDirection[0],
    size: buttonConfig.size[2],
    tag: buttonConfig.tag[0],
    type: buttonConfig.type[0],
    tooltipPosition: buttonConfig.tooltipPosition[0],
    disabled: false,
    loading: false,
};

Button.propTypes = {
    active: PropTypes.bool,
    animation: PropTypes.bool,
    appearance: PropTypes.oneOf(buttonConfig.appearance),
    badge: PropTypes.number,
    children: PropTypes.any,
    className: PropTypes.string,
    color: PropTypes.oneOf(buttonConfig.color),
    cornerRadius: PropTypes.oneOf(buttonConfig.cornerRadius),
    flexibility: PropTypes.oneOf(buttonConfig.flexibility),
    icon: PropTypes.string,
    iconSize: Icon.propTypes.size,
    itemsDirection: PropTypes.oneOf(buttonConfig.itemsDirection),
    size: PropTypes.oneOf(buttonConfig.size),
    tag: PropTypes.oneOf(buttonConfig.tag),
    type: PropTypes.oneOf(buttonConfig.type),
    tooltip: PropTypes.string,
    to: PropTypes.string,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    tooltipPosition: PropTypes.oneOf(buttonConfig.tooltipPosition),
};

export default Button;
