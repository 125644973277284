import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';

import Logo from '../logo';
import Animate from '../animate';

import { routes } from '../../constants/routes';
import { useScrollDirection } from '../../utils/useScrollDirection';
import { navLinkClick, toggleClass } from '../../utils/helpers';
import { blogEnabled, webSiteTitle } from '../../constants/config';

import './header.scss';

const Header = props => {
    const {
        blogAvailable,
    } = props;

    const scrollDirection = useScrollDirection();
    const location = useLocation();

    const [showMenu, setShowMenu] = useState(false);
    const [isHeaderSticky, setIsHeaderSticky] = useState(false);

    useEffect(() => {
        toggleClass(isHeaderSticky, 'sticky');
    }, [isHeaderSticky]);

    useEffect(() => {
        const watchForSticky = () => {
            setIsHeaderSticky(window.scrollY > window.screen.height / 2);
        };
        window.addEventListener('scroll', watchForSticky);
        return () => window.removeEventListener('scroll', watchForSticky);
    }, []);

    useEffect(() => {
        setShowMenu(false);
    }, [location.pathname, location.hash]);

    return (
        <>
            <header
                className={classnames(
                    {
                        'hide': scrollDirection === 'down',
                        'show-menu': showMenu,
                    }
                )}
            >
                <ul className='header-content'>
                    <li>
                        <Animate>
                            <NavLink
                                to='/'
                                className='logo-holder'
                                onClick={() => navLinkClick('/')}
                                aria-label={webSiteTitle}
                            >
                                <Logo/>
                            </NavLink>
                        </Animate>
                    </li>
                    <li>
                        <div className='navigation-container'>
                            <div className='navigation-holder'>
                                <Animate>
                                    <nav>
                                        {routes.map((route, index) => (!route.invisible && !route.hidden) && (route.slug === 'blog' ? (blogAvailable && blogEnabled) : true) && (
                                            <React.Fragment key={index}>
                                                {route.target ? (
                                                    <a
                                                        href={route.url}
                                                        target={route.target}
                                                    >
                                                        {route.name}
                                                    </a>
                                                ) : (
                                                    <NavLink
                                                        to={route.url}
                                                        onClick={() => navLinkClick(route.url)}
                                                        exact={route.url === '/'}
                                                    >
                                                        {route.name}
                                                    </NavLink>
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </nav>
                                </Animate>
                            </div>
                        </div>
                        <div className='toggle-navigation-holder'>
                            <Animate delay={400}>
                                <button
                                    className={classnames(
                                        {
                                            'active': showMenu,
                                        }
                                    )}
                                    onClick={() => setShowMenu(val => !val)}
                                />
                            </Animate>
                        </div>
                    </li>
                </ul>
            </header>
        </>
    )
};

Header.propTypes = {
    blogAvailable: PropTypes.bool,
};

export default Header;
